import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import SupportRequestDialog from '../dialogs/SupportRequestDialog';
import useOperativeRequestTypes from '../../hooks/useOperativeRequestTypes';

const SupportRequestMenu = ({ documentIds, documentType, anchor, onClose }) => {
  const [openSupportRequestDialog, setOpenSupportRequestDialog] = useState(false);
  const [requestType, setRequestType] = useState({});
  const { loading, data: operativeRequestTypes } = useOperativeRequestTypes(documentIds);

  return (
    <>
      <Menu
        id="support-request-menu"
        anchorEl={anchor}
        open={Boolean(anchor)}
        onClose={onClose}
      >
        {loading ? (
          <MenuItem disabled>
            <CircularProgress size={20} sx={{ mr: 1 }} />
            Cargando...
          </MenuItem>
        ) : (
          operativeRequestTypes.map(({ id, code, active, name }) => (
            <MenuItem
              id={id}
              key={id}
              sx={{ my: 0.5 }}
              onClick={() => {
                setRequestType({ id, name, code });
                setOpenSupportRequestDialog(true);
              }}
              disabled={!active}
            >
              {name}
            </MenuItem>
          ))
        )}
      </Menu>
      {openSupportRequestDialog && (
        <SupportRequestDialog
          open={openSupportRequestDialog}
          documentIds={documentIds}
          documentType={documentType}
          requestType={requestType}
          onClose={onClose}
        />
      )}
    </>
  );
};

SupportRequestMenu.propTypes = {
  documentType: PropTypes.string.isRequired,
  anchor: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]).isRequired,
  onClose: PropTypes.func.isRequired,
  documentIds: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
};

export default SupportRequestMenu;

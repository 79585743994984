import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { REQUESTING_PLATFORM_TO_CODES } from '@fingo/lib/constants/requesting-platform';
import { OPERATIVE_REQUEST_TYPES, OPERATIVE_REQUEST_INVOICES } from '../graphql/operative-requests';

const OPERATIVE_REQUEST_CODES = {
  MOVE_TO_PENDINGRATIFICATION: 'MOVE_TO_PENDINGRATIFICATION',
  MOVE_TO_PENDINGDOCUMENTSREVIEW: 'MOVE_TO_PENDINGDOCUMENTSREVIEW',
  CHANGE_OPERATION_CONDITIONS: 'CHANGE_OPERATION_CONDITIONS',
  CHANGE_TO_INTERNAL_CESION: 'CHANGE_TO_INTERNAL_CESION',
  UNDO_CONCILIATION: 'UNDO_CONCILIATION',
  CHANGE_COMMISSION: 'CHANGE_COMMISSION',
  MOVE_TO_EVALUATING: 'MOVE_TO_EVALUATING',
};

const OPERATIVE_REQUEST_TYPES_CODES = {
  [REQUESTING_PLATFORM_TO_CODES.FINGO]: Object.values(OPERATIVE_REQUEST_CODES),
  [REQUESTING_PLATFORM_TO_CODES.SANTANDER]: [
    OPERATIVE_REQUEST_CODES.CHANGE_OPERATION_CONDITIONS,
    OPERATIVE_REQUEST_CODES.MOVE_TO_EVALUATING,
  ],
};

const useOperativeRequestTypes = (documentIds) => {
  const { data: invoicesData, loading: invoicesLoading } = useQuery(OPERATIVE_REQUEST_INVOICES, {
    variables: { id_In: documentIds },
    skip: !documentIds?.length,
  });

  const requestingPlatformCode = useMemo(() => {
    if (!invoicesData?.invoices?.edges) {
      return [REQUESTING_PLATFORM_TO_CODES.FINGO];
    }

    const documents = invoicesData.invoices.edges.map((edge) => edge.node);
    const platforms = new Set(documents.map(
      (doc) => doc?.company?.conservativeTycRequestingPlatform?.code
        || REQUESTING_PLATFORM_TO_CODES.FINGO,
    ));

    return Array.from(platforms);
  }, [invoicesData]);

  const mostRestrictivePlatform = useMemo(() => requestingPlatformCode.reduce(
    (mostRestrictive, current) => {
      const currentCodes = OPERATIVE_REQUEST_TYPES_CODES[current] || [];
      const restrictiveCodes = OPERATIVE_REQUEST_TYPES_CODES[mostRestrictive] || [];
      return currentCodes.length < restrictiveCodes.length ? current : mostRestrictive;
    },
    requestingPlatformCode[0],
  ), [requestingPlatformCode]);

  const {
    data: operativeRequestTypesData,
    loading: operativeRequestTypesLoading,
  } = useQuery(OPERATIVE_REQUEST_TYPES, {
    variables: {
      code_In: OPERATIVE_REQUEST_TYPES_CODES[mostRestrictivePlatform],
    },
  });

  return {
    loading: invoicesLoading || operativeRequestTypesLoading,
    data: operativeRequestTypesData?.operativeRequestTypes?.edges?.map((edge) => edge.node) || [],
  };
};

export default useOperativeRequestTypes;

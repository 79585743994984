import React from 'react';
import { AvatarCell, Status } from '@fingo/lib/components/dataGridCells';
import {
  InvoiceCessionsTooltip,
  MoneyCell,
  TextCell,
  LongTextCell,
  CompanyCell,
  PurchaseOrderNumberCell,
} from '@fingo/lib/components/cells';
import { orderingStatusToSpanish, formatDateTime } from '@fingo/lib/helpers';
import { CommissionForm, RatesForm, SimpleForm, SurplusForm } from '../components/forms';
import OperativeRequestTypeCell from '../components/cells/OperativeRequestTypeCell';
import { getContenTypeModel } from '../helpers/operative-requests';

export const OPERATIVE_REQUEST_TYPE_TO_COMPONENT = {
  MOVE_TO_PENDINGRATIFICATION: SimpleForm,
  MOVE_TO_PENDINGDOCUMENTSREVIEW: SimpleForm,
  MOVE_TO_EVALUATING: SimpleForm,
  CHANGE_OPERATION_CONDITIONS: RatesForm,
  CHANGE_TO_INTERNAL_CESION: SimpleForm,
  CHANGE_COMMISSION: CommissionForm,
  UNDO_CONCILIATION: SimpleForm,
  CHANGE_SURPLUS: SurplusForm,
};

export const REQUEST_TYPE_TO_EMOJI = {
  MOVE_TO_PENDINGRATIFICATION: '📬',
  MOVE_TO_PENDINGDOCUMENTSREVIEW: '📑',
  CHANGE_OPERATION_CONDITIONS: '💸',
  CHANGE_TO_INTERNAL_CESION: '⏳',
  UNDO_CONCILIATION: '🔐',
  MOVE_TO_EVALUATING: '🔁',
};

export const OPERATIVE_REQUESTS_COLUMNS = [
  {
    field: 'id',
    headerName: 'N° Solicitud',
    width: 80,
    valueGetter: ({ row }) => row.id,
  },
  {
    field: 'createdAt',
    headerName: 'Fecha creación',
    width: 120,
    valueGetter: ({ row }) => formatDateTime(row.createdAt),
  },
  {
    field: 'requester',
    headerName: 'Solicitante',
    headerAlign: 'center',
    width: 80,
    renderCell: ({ row }) => <AvatarCell user={row.requester} align="left" />,
  },
  {
    field: 'operativeRequestType',
    headerName: 'Tipo de solicitud',
    flex: 1,
    renderCell: ({ row }) => (
      <OperativeRequestTypeCell
        operativeRequestType={row.operativeRequestType}
      />
    ),
  },
  {
    field: 'documentType',
    headerName: 'Tipo documentos',
    flex: 1,
    valueGetter: ({ row }) => {
      const documentsCount = row.operativeRequestManagers.length;
      const plural = documentsCount > 1;
      const contentTypeModel = getContenTypeModel(row);
      const isInvoice = contentTypeModel === 'invoice';
      if (isInvoice) {
        return `${documentsCount} Factura${plural ? 's' : ''}`;
      }
      return `${documentsCount} Ordering${plural ? 's' : ''}`;
    },
  },
  {
    field: 'comment',
    headerName: 'Comentario',
    flex: 2,
    renderCell: ({ row }) => (
      <LongTextCell title="Comentario" text={row.comment} />
    ),
  },
];

export const OPERATIVE_REQUEST_INVOICES_COLUMNS = [
  {
    field: 'folio',
    headerName: 'Folio',
    renderCell: ({ row }) => (
      <InvoiceCessionsTooltip invoice={row}>
        <span>
          <TextCell text={row.folio} />
        </span>
      </InvoiceCessionsTooltip>
    ),
    sortable: false,
    width: 80,
  },
  {
    field: 'amountWithIva',
    headerName: 'Monto',
    renderCell: ({ row }) => <MoneyCell amount={row.amountWithIva} />,
  },
  {
    field: 'company_Name',
    headerName: 'Empresa',
    renderCell: ({ row }) => <CompanyCell company={row.company.masterEntity} />,
    flex: 1,
  },
  {
    field: 'receiver_sortable',
    headerName: 'Receptor',
    renderCell: ({ row }) => <CompanyCell company={row.receiver} />,
    flex: 1,
  },
  {
    field: 'status',
    headerName: 'Estado',
    renderCell: ({ row }) => (
      <Status status={row.status} paymentDate={row.paymentDate} oneStepper />
    ),
  },
];

export const OPERATIVE_REQUEST_PURCHASE_ORDERS_COLUMNS = [
  {
    field: 'orderNumber',
    headerName: 'Número',
    sortable: false,
    renderCell: ({ row }) => (
      <PurchaseOrderNumberCell
        purchaseOrderId={row.id}
        orderNumber={row.orderNumber}
      />
    ),
  },
  {
    field: 'totalAmount',
    headerName: 'Monto total',
    headerAlign: 'left',
    align: 'left',
    sortable: false,
    renderCell: ({ row }) => <MoneyCell amount={row.totalAmount} />,
  },
  {
    field: 'company_Name',
    headerName: 'Empresa',
    renderCell: ({ row }) => <CompanyCell company={row.company.masterEntity} />,
    flex: 1,
  },
  {
    field: 'purchaser_Name',
    headerName: 'Comprador',
    renderCell: ({ row }) => <CompanyCell company={row.purchaser} />,
    flex: 1,
  },
  {
    field: 'orderingStatus',
    headerName: 'Estado',
    valueGetter: ({ row }) => orderingStatusToSpanish[row.status],
  },
];
